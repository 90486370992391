<template>
  <ClientsForm
    :isLoading="isLoading"
    @save="createClient"
    :client="client"
    :password="password"
    @validate="validate"
    :type="'create'"
    ref="form"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    ClientsForm: () => import('./ClientsForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    askToLeave: true,
    client: {
      type: 'client',
      client_contact: [],
      client_address: [],
      email: null,
      password: null,
      person_type: 'PJ',
    },
    password: null,
    isLoading: false,
  }),
  methods: {
    validate(value) {
      this.isFormValid = value
    },
    checkPass() {
      if (this.client.password != this.password) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Os password do cadastro não são iguais.',
          caption: 'Verifique se os campos estão preenchidos corretamente.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
      return null
    },
    createClient() {
      //checar o password
      this.checkPass()

      if (this.client.client_contact.length == 0) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar cadastrar este cliente',
          caption: 'É necessário cadastrar no mínimo 1 contato.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      } else if (this.client.seller_id == null || this.client.seller_id == '') {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar cadastrar este usuário',
          caption: 'Verifique se o campo Vendedor está preenchido.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      } else {
        this.askToLeave = false
        this.$api
          .post('people', this.client)
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Cliente cadastrado com sucesso',
              confirm: () => {
                this.$router.push({ name: 'clients' })
              },
            })
          })
          .catch((err) => {
            let message = ''
            if (
              err.response.data.message &&
              err.response.data.message.password
            ) {
              message = 'O campo senha é obrigatório'
            } else {
              message = err.response.data.message
            }
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Falha ao tentar cadastrar este cliente',
              caption: message,
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
